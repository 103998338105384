<template>
  <el-form ref="form" :model="formModel" :rules="formRules" label-position="left" label-width="auto">
    <slot name="formTop" />
    <el-row :gutter="10">
      <el-col
        v-for="(item, index) in formItems"
        :key="index"
        :span="item.elColSpan || 8"
        :offset="item.elColOffset || 0"
        :push="item.elColPush || 0"
        :pull="item.elColPull || 0"
        :xs="item.elColXs || (item.elColSpan || 8)"
        :sm="item.elColSm || (item.elColSpan || 8)"
        :md="item.elColMd || (item.elColSpan || 8)"
        :lg="item.elColLg || (item.elColSpan || 8)"
        :xl="item.elColXl || (item.elColSpan || 8)"
      >
        <el-form-item v-if="item.hide !== false" :prop="index">
          <CustomFormLabel slot="label" :options="item" />
          <slot v-if="item.slot" :name="item.slot" :formModel="formModel" :item="item" />
          <component
            :is="item.component"
            v-else
            :ref="index"
            :form-model="formModel"
            :field="index"
            :options="item"
          />
          <el-alert
            v-if="item.alert"
            :type="item.alert.type || 'info'"
            :closable="item.alert.closable"
            :center="item.alert.center"
            :show-icon="item.alert.showIcon"
            :effect="item.alert.effect || 'light'"
            style="margin-top: 10px;"
          >
            <template v-if="item.alert.title" slot="title">
              <div v-html="item.alert.title" />
            </template>
            <slot v-if="item.alert.description">
              <div v-html="item.alert.description" />
            </slot>
          </el-alert>
        </el-form-item>
      </el-col>
    </el-row>
    <slot name="formBottom" />
  </el-form>
</template>

<script>
import { default as formComponents } from '@/components/CustomForm/components'
export default {
  name: 'CustomForm',
  components: {
    // 动态注册组件
    ...formComponents
  },
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    formItems: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    formRules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
