<template>
  <Tinymce
    :value="formModel[field]"
    :disabled="options['disabled']"
    @input="input"
  />
</template>

<script>
import Tinymce from '@/components/Tinymce/index'
export default {
  name: 'CustomFormTinymce',
  components: { Tinymce },
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    input(value) {
      this.formModel[this.field] = value
    }
  }
}
</script>

<style scoped lang="scss">

</style>
