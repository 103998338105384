import { render, staticRenderFns } from "./custom-form-radio.vue?vue&type=template&id=81e80042&scoped=true"
import script from "./custom-form-radio.vue?vue&type=script&lang=js"
export * from "./custom-form-radio.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81e80042",
  null
  
)

export default component.exports