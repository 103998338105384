<template>
  <span v-if="options.label || options.intro || options.labelButton">
    <el-tooltip v-if="options.intro">
      <div slot="content" class="tooltip-content" v-html="options.intro" />
      <span>
        {{ options.label }}
        <i class="el-icon-info" />
      </span>
    </el-tooltip>
    <span v-else>
      {{ options.label }}
    </span>
    <el-button
      v-if="options.labelButton"
      :size="options.labelButton.size || ''"
      :type="options.labelButton.type || ''"
      :plain="options.labelButton.plain === true"
      :round="options.labelButton.round === true"
      :circle="options.labelButton.circle === true"
      :loading="options.labelButton.loading === true"
      :disabled="options.labelButton.disabled === true"
      :icon="options.labelButton.icon || ''"
      @click="options.labelButton.click() || null"
    >
      {{ options.labelButton.content }}
    </el-button>
  </span>
</template>

<script>
export default {
  name: 'CustomFormLabel',
  props: {
    options: {
      type: Object,
      require: true,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
