<template>
  <div>
    <el-radio
      v-for="(item, index) in options['data']"
      :key="index"
      v-model="formModel[field]"
      :label="typeof item === 'object' ? item[label] : index"
      :disabled="options['disabled'] || item['disabled']"
    >
      {{ typeof item === 'object' ? item[text] : item }}
    </el-radio>
  </div>
</template>

<script>
export default {
  name: 'CustomFormRadio',
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    label() {
      return this.options['dataField'] && this.options['dataField']['label'] ? this.options['dataField']['label'] : 'label'
    },
    text() {
      return this.options['dataField'] && this.options['dataField']['text'] ? this.options['dataField']['text'] : 'text'
    }
  }
}
</script>

<style scoped lang="scss">

</style>
