<template>
  <el-date-picker
    v-model="formModel[field]"
    :readonly="options['readonly']"
    :disabled="options['disabled']"
    :editable="options['editable']"
    :clearable="options['clearable']"
    :type="options['type']"
    :format="options['format']"
    :align="options['align'] || 'center'"
    :value-format="options['valueFormat']"
    :placeholder="options['placeholder'] || `请选择${options['label']}`"
    :start-placeholder="options['startPlaceholder'] || `${options['label']} 开始时间`"
    :end-placeholder="options['endPlaceholder'] || `${options['label']} 结束时间`"
    style="width: 100%;"
  />
</template>

<script>
export default {
  name: 'CustomFormDatePicker',
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
