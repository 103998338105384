<template>
  <el-input-number
    v-model="formModel[field]"
    :min="options['min']"
    :max="options['max']"
    :step="options['step']"
    :step-strictly="options['stepStrictly']"
    :precision="options['precision']"
    :disabled="options['disabled']"
    :controls="options['controls']"
    controls-position="right"
    :placeholder="options['placeholder'] || `请输入${options['label']}`"
    style="width: 100%;"
  />
</template>

<script>
export default {
  name: 'CustomFormNumber',
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
