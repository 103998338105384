<template>
  <el-select
    v-model="formModel[field]"
    :disabled="options['disabled']"
    :clearable="options['clearable'] !== false"
    :placeholder="options['placeholder'] || `请选择${options.label}`"
    :multiple="options['multiple']"
    :filterable="options['filterable']"
    :collapse-tags="options['collapseTags']"
    :multiple-limit="options['multipleLimit']"
    style="width: 100%;"
    @change="change"
  >
    <el-option
      v-for="(item, index) in options['data']"
      :key="index"
      :label="item[label]"
      :value="item[value]"
      :disabled="item['disabled']"
    />
  </el-select>
</template>

<script>
export default {
  name: 'CustomFormSelect',
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    label() {
      return this.options['dataField'] && this.options['dataField']['label'] ? this.options['dataField']['label'] : 'label'
    },
    value() {
      return this.options['dataField'] && this.options['dataField']['value'] ? this.options['dataField']['value'] : 'value'
    }
  },
  methods: {
    change(value) {
      if ('change' in this.options && typeof this.options.change === 'function') {
        this.options.change(value)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
