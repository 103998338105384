<template>
  <el-input
    v-model="formModel[field]"
    type="textarea"
    :minlength="options['minlength']"
    :maxlength="options['maxlength']"
    :show-word-limit="options['showWordLimit']"
    :placeholder="options['placeholder'] || `请输入${options.label}`"
    :clearable="options['clearable'] !== false"
    :disabled="options['disabled']"
    :prefix-icon="options['prefixIcon']"
    :suffix-icon="options['suffixIcon']"
    :rows="options['rows'] || 3"
    :autosize="options['autosize'] || { minRows: 3, maxRows: 10 }"
    :readonly="options['readonly']"
  />
</template>

<script>
export default {
  name: 'CustomFormTextarea',
  props: {
    formModel: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    field: {
      type: String,
      require: true,
      default: ''
    },
    options: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
