<template>
  <div class="upload-container">
    <el-upload
      :action="action"
      :headers="headers"
      :multiple="false"
      :show-file-list="false"
      :disabled="disabled"
      accept=".jpg, .jpeg, .png, .webp, .gif, .mp4"
      :on-success="handleSuccess"
    >
      <el-button icon="el-icon-upload" size="mini" type="primary" :disabled="disabled">
        上传图片/视频
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import api from '@/store/API/api'

export default {
  name: 'EditorSlideUpload',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      action: process.env.NODE_ENV === 'development' ? '/api' + api.fileUploadForAvatar : process.env.VUE_APP_BASEURL + api.fileUploadForAvatar,
      headers: { Authorization: sessionStorage.token }
    }
  },
  methods: {
    handleSuccess(response, file) {
      this.$emit('successCBK', response, file)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
